import React from "react";
import './LicenseTestBench.css';
import Cookies from "js-cookie";
import axios from "axios";


class LicenseList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newLicense: false
        }
    }
    
    handleNewLicense = async (jsonBody) => {
        this.setState({
            newLicense: false
        });
        this.props.handleNewLicense(jsonBody);
    }


    onNewLicense = async () => {
        this.setState({
            newLicense: true
        });
    }

    licenseListView() {
        if (this.state.newLicense) {
            return (
                <NewLicense
                    handleNewLicense={(jsonBody) => this.handleNewLicense(jsonBody)}
                />
            )
        } else {
            const licenseList = this.props.licenseList;
            return licenseList.map((license) =>
                <License
                    license={license}
                    key={license["serialNumber"]}
                    onClick={
                        (license) => this.props.handleDelete(license)
                    }
                />
            )
        }
    }
    render() {
        return (
            <div>
                <div className="ltb-license-list-container">
                    <h2>Licenses</h2>
                    <button
                        className="ltb-button"
                        onClick={() => this.onNewLicense()}
                    >
                        New
                    </button>
                </div>
                <div>
                    {this.licenseListView()}
                </div>
            </div>
        )
    }
}

class License extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // state
        }
    }

    deleteButton() {
        return (
            <button className="ltb-btn-delete ltb-button" onClick={
                () => this.props.onClick(this.props.license)}>
                Delete
            </button>
        )
    }

    render() {
        const license = this.props.license;
        const serialNumber = license["serialNumber"];

        return (
            <div key={serialNumber}>
                <div className="ltb-license-list-item" key={serialNumber}>
                    <div className="ltb-license-list-serial">
                        {serialNumber}
                    </div>
                    {this.deleteButton()}
                </div>
            </div>
        )

    }
}

class NewLicense extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <NewLicenseForm
                    handleNewLicense={(jsonBody) => this.props.handleNewLicense(jsonBody)}
                />
            </div>
        )
    }
}

class NewLicenseForm extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            bearerToken: "",
            serialNumber: "",
            user: "",
            skuId: 1,
            skus: [],
            type: "advanced",
            status: {
                breakable: true,
                busy: false,
                locked: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const bearerToken = Cookies.get('token');
        this.setState({
            bearerToken: bearerToken,
        });
        this.getSkus(bearerToken);
    }

    getSkus = async (bearerToken) => {
        var skus;
        try {
            const response = await axios.get(
                "/api/v1/admin/license-test/skus",
                {
                    headers: {
                        Authorization: 'Bearer ' + bearerToken
                    },
                },
            );
            if (response.status == 200 && response.data.success) {
                this.setState({
                    skus: response.data.skus
                })
            } else {
                throw {message: "Skus not found"}
            }
        } catch (error) {
            console.warn(error)
        }
    }

    handleChange(event) {
        if (event.target.id == "serialNumber") {
            this.setState({ serialNumber: event.target.value });    
        } else if (event.target.id == "user") {
            this.setState({ user: event.target.value });    
        } else if (event.target.id == "skuId") {
            this.setState({ skuId: event.target.value });
        } else if (event.target.id == "type") {
            this.setState({ type: event.target.value });
        } else if (event.target.id == "licenseState") {
            const statusCase = event.target.value;
            if (statusCase === "unlocked") {
                this.setState({
                    status: {
                        locked: false,
                        breakable: true,
                        busy: false
                    }
                });
            } else if (statusCase === "autoBreakable") {
                this.setState({
                    status: {
                        locked: true,
                        breakable: true,
                        busy: false
                    }
                });
            } else if (statusCase === "manualBreakable") {
                this.setState({
                    status: {
                        locked: true,
                        breakable: true,
                        busy: true
                    }
                });
            } else if (statusCase === "notBreakable") {
                this.setState({
                    status: {
                        locked: true,
                        breakable: false,
                        busy: true
                    }
                });
            }
        }
    }

    handleSubmit(event) {
        const status = this.state.status;
        const skuId = this.state.skuId;
        const skus = this.state.skus;
        var skuIndex = 0;
        skus.forEach((sku, index) => {
            if (sku.skuId == skuId) {
                skuIndex = index;
            }
        });

        const what = {
            productName: this.state.skus[skuIndex].productName,
            major: this.state.skus[skuIndex].major,
            minor: this.state.skus[skuIndex].minor,
            type: this.state.type
        }
        const jsonBody = {
            skuId: skuId,
            what: what,
            status: status
        }

        event.preventDefault();
        this.props.handleNewLicense(jsonBody);

    }

    render() {

        const skus = this.state.skus;

        let skuList = skus.length > 0
            && skus.map((sku) => {
                return (
                    <option key={sku.skuId} value={sku.skuId}>{sku.productName} {sku.major}.{sku.minor}</option>
                )
            }, this);
        
        return (
            <form onSubmit={this.handleSubmit} className="ltb-create-license-form">
                <label>
                    <b>Product</b>
                    <select id="skuId" value={this.state.value} onChange={this.handleChange}>
                         {skuList}
                    </select>
                    <br /><br />
                    <b>Type</b>
                    <select id="type" value={this.state.value} onChange={this.handleChange}>
                        <option value="advanced">Advanced</option>
                        <option value="premium">Premium</option>
                    </select>
                    <br /><br />
                    <b>License State</b>
                    <select id="licenseState" value={this.state.value} onChange={this.handleChange}>
                        <option value="unlocked">Unlocked</option>
                        <option value="autoBreakable">Locked + Auto Break</option>
                        <option value="manualBreakable">Locked + Manual Break</option>
                        <option value="notBreakable">Locked + Not Breakable</option>
                    </select>
                </label>
                <br /><br />
                <br /><br />
                <input className=".ltb-button" type="submit" value="Create New License" />
            </form>
        )
    }
}


export default LicenseList;